(function () {
  "use strict";

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  // https://davidwalsh.name/javascript-debounce-function
  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  // full height columns
  function fullHeight() {
    const BREAKPOINT = 992;
    var width = window.innerWidth;

    var elements = [
      document.getElementsByClassName("fh5co-section-heading-bg").item(0),
      document.getElementsByClassName("fh5co-cover-intro-bg").item(0),
    ];

    // reset height first
    elements.forEach((el) => {
      el.setAttribute("style", "height: auto");
    });

    // match height of containers when they are columns
    if (width > BREAKPOINT) {
      var maxHeight = 0;

      elements.forEach((el) => {
        var elementHeight = el.clientHeight;
        if (elementHeight > maxHeight) {
          maxHeight = elementHeight;
        }
      });

      // match height
      elements.forEach((el) => {
        el.setAttribute("style", `height: ${maxHeight}px`);
      });
    }
  }

  // trigger on load
  fullHeight();

  // and also on resize
  window.addEventListener("resize", debounce(fullHeight, 250));
})();
